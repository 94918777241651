import $ from "jquery";
export var TopNavDropDown = function () {
    $('#profile-user').on('click', function () {
        if ($(this).hasClass("nx-open")) {
            $(this).removeClass("nx-open");
        }
        else {
            $(this).addClass("nx-open");
        }
        $('[data-submenu-for="profile-user"]').slideToggle("top");
    });
    $('#mobile-profile-user').on('click', function () {
        if ($(this).hasClass("nx-open")) {
            $(this).removeClass("nx-open");
        }
        else {
            $(this).addClass("nx-open");
        }
        $('[data-submenu-for="mobile-profile-user"]').slideToggle("top");
    });
};
