import $ from "jquery";
export var userClearableFileInputText = function () {
    $('input[type="file"]').on("change", function () {
        if ($(this).val() != "") {
            $(this).addClass("nx-widgets-clearablefileinput-hasvalue");
        }
        else {
            $(this).removeClass("nx-widgets-clearablefileinput-hasvalue");
        }
    });
    $('.nx-widgets-clearablefileinput').on('click', function (event) {
        $(event.target).find('input[type="file"]').click();
    });
    $("input[type='file']").click(function (event) {
        // Prevent the click event from bubbling up to the parent div
        event.stopPropagation();
    });
    $('#boncoup_documents').on('change', 'input[type="file"]', function () {
        if ($(this).val() != "") {
            $(this).addClass("nx-widgets-clearablefileinput-hasvalue");
        }
    });
    $('#project_document_formset').on('change', 'input[type="file"]', function () {
        if ($(this).val() != "") {
            $(this).addClass("nx-widgets-clearablefileinput-hasvalue");
        }
    });
    $('#company_files_formset ').on('change', 'input[type="file"]', function () {
        if ($(this).val() != "") {
            $(this).addClass("nx-widgets-clearablefileinput-hasvalue");
        }
    });
};
