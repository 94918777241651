import $ from "jquery";
export var ScrollToTop = function () {
    var scroller = $('.scroll-to-top');
    scroller.on('click', function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    $(window).scroll(function () {
        var height = $(window).scrollTop();
        if (height > 400) {
            scroller.addClass('show');
        }
        else {
            scroller.removeClass('show');
        }
    });
};
