import "../styles/main.scss";
import 'react-tooltip/dist/react-tooltip.css';
import "bootstrap";
import $ from "jquery";
import { userProfileTabs } from "../components/UserProfileTabs";
import { userClearableFileInputText } from "../components/UserClearableFileInputText";
import { InputDropdownMultiSelection } from "../components/InputDropdownMultiSelection";
import { InputSideFilter } from "../components/InputSideFilter";
import { AlertMessages } from "../components/AlertMessages";
import { TopNavDropDown } from "../components/TopNavDropDown";
import { RangeSliders } from "../components/RangeSliders";
import { RichTextPlugins } from "../components/RichTextPlugins";
import { ScrollToTop } from "../components/ScrollToTop";
import { TimelineView } from "../components/TimelineView";
import { PopupNotes } from "../components/PopupNotes";
import { MobNav } from "../components/MobNav";
import { IntervenerSupplierProfile } from "./Admin-js/IntervenerSupplierProfile";
import { Registration } from "./Admin-js/Registration";
import { RegistrationStep2 } from "./Admin-js/RegistrationStep2";
import { AddAdresse } from "./Admin-js/AddAdresse";
import { AutoCompleteAddress } from "./Admin-js/AutoCompleteAddress";
import { SupplierProfile } from "./Admin-js/SupplierProfile";
import { ContractorProfile } from "./Admin-js/ContarctorProfile";
import { Workers } from "../components/Workers";
import { SubMenu } from "../components/SubMenu";
import { Tooltip } from "../components/Tooltip";
import { initFcmServiceWorker } from "../components/notifications/firebase/firebase";
import { NotificationDropDown, TwillioNotificationDropDown } from "../components/notifications/NotificationDropDown";
import { AddDocumentsProject } from "./Admin-js/AddDocumentsProject";
import { BonsCoupsForm } from "./Admin-js/BonsCoupsForm";
import { ProjectsEnableDisable } from "../components/ProjectsEnableDisable";
import { PreferencesNotificationsUser } from "../components/PreferencesNotificationsUser";
import { ProjectAddress } from "./ProjectsMembers/ProjectAddress";
import { SuccursalesCompanies } from "./CompaniesMembers/SuccursalesCompanies";
import { Select2Init } from "../components/Select2Init";
import { DateTimeProjects } from "./Admin-js/DateTimeProjects";
import { MatchHeightInit } from "../components/MatchHeightInit";
import { CompanyFilesForm } from "./Admin-js/CompanyFilesForm";
import { CompanyVideosForm } from "./Admin-js/CompanyVideosForm";
import { CompanyFormsetNord49 } from "./Admin-js/CompanyFormsetNord49";
import { CompanyFormsetExperience } from "./Admin-js/CompanyFormsetExperience";
import { SocialMedia } from "./Admin-js/SocialMedia";
import { ManageProjectOtherAddress } from "./Admin-js/ProjectsOthers";
import { ProjectJeSJeCherche } from "./Admin-js/ProjectJeSuisJeCherche";
import CookieConsent from "../components/cookieconsent";
import { MenuProfileCompany } from "./Admin-js/MenuProfileCompany";
$(document).ready(function () {
    Tooltip();
    userProfileTabs();
    userClearableFileInputText();
    InputDropdownMultiSelection();
    InputSideFilter();
    AlertMessages();
    TopNavDropDown();
    RangeSliders();
    RichTextPlugins();
    ScrollToTop();
    TimelineView();
    PopupNotes();
    MobNav();
    SupplierProfile();
    SubMenu();
    Registration();
    RegistrationStep2();
    AddAdresse();
    AutoCompleteAddress();
    IntervenerSupplierProfile();
    ContractorProfile();
    Workers();
    initFcmServiceWorker();
    NotificationDropDown();
    TwillioNotificationDropDown();
    AddDocumentsProject();
    BonsCoupsForm();
    ProjectsEnableDisable();
    PreferencesNotificationsUser();
    ProjectAddress();
    SuccursalesCompanies();
    Select2Init();
    DateTimeProjects();
    MatchHeightInit();
    CompanyFilesForm();
    CompanyVideosForm();
    CookieConsent.ready();
    CompanyFormsetNord49();
    CompanyFormsetExperience();
    SocialMedia();
    ManageProjectOtherAddress();
    ProjectJeSJeCherche();
    MenuProfileCompany();
});
