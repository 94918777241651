import $ from "jquery";
import { showNotification } from "./NotificationsContextuelles";
export var PreferencesNotificationsUser = function () {
    $(document).ready(function () {
        var form = document.querySelector("#preference-notifications");
        if (form) {
            var spanElement = $(form).find('span.select2.select2-container.select2-container--default');
            if (spanElement.length) {
                spanElement.css('width', '25%');
            }
            var language = document.getElementById("language-code").value;
            var url;
            if (language === "fr") {
                url = "/fr/utilisateurs/profil/preferences-notifications/";
            }
            else {
                url = "/en/users/profile/preferences-notifications/";
            }
            $("#id_preferred_language").on("change", function (event) {
                var preferredLanguage = event.target.value;
                var formData = new FormData();
                formData.append("preferred_language", preferredLanguage);
                fetch(url, {
                    method: "POST",
                    body: formData,
                })
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                    if (data.messages && data.messages.length > 0) {
                        data.messages.forEach(function (message) {
                            showNotification(message, "success");
                        });
                    }
                    else {
                        showNotification("No messages to display", "neutral");
                    }
                })
                    .catch(function (error) {
                    showNotification("Erreur lors de la sauvegarde des données", "error");
                    console.log("Erreur lors de la sauvegarde des données :", error);
                });
            });
            var radioButtons = form.querySelectorAll('input[type="radio"]');
            var preferredLanguageUser_1 = document.querySelector('select[name="preferred_language"]').value;
            if (radioButtons) {
                radioButtons.forEach(function (button) {
                    button.addEventListener("change", function () {
                        // Récupérez les valeurs des cases à cocher
                        var recevoir_message_prive = document.querySelector('input[name="recevoir_message_prive"]:checked').value;
                        // const recevoir_message_publique = document.querySelector(
                        //   'input[name="recevoir_message_publique"]:checked'
                        // ).value;
                        var recevoir_reaction = document.querySelector('input[name="recevoir_reaction"]:checked').value;
                        // Créer un objet FormData avec les nouvelles valeurs
                        var formData = new FormData();
                        formData.append("recevoir_message_prive", recevoir_message_prive);
                        formData.append("preferred_language", preferredLanguageUser_1);
                        // formData.append(
                        //   "recevoir_message_publique",
                        //   recevoir_message_publique
                        // );
                        formData.append("recevoir_reaction", recevoir_reaction);
                        // Envoyer la requête POST avec fetch
                        fetch(url, {
                            method: "POST",
                            body: formData,
                        })
                            .then(function (response) { return response.json(); })
                            .then(function (data) {
                            if (data.messages && data.messages.length > 0) {
                                data.messages.forEach(function (message) {
                                    showNotification(message, "success");
                                });
                            }
                            else {
                                showNotification("No messages to display", "neutral");
                            }
                        })
                            .catch(function (error) {
                            console.log("Erreur lors de la sauvegarde des données :", error);
                        });
                    });
                });
            }
            var checkboxButton_1 = document.querySelector('input[type="checkbox"]');
            var checkbox = $("#id_recevoir_bulletin");
            var label = $('label[for="id_recevoir_bulletin"]');
            // Ajouter une classe pour styler le label
            label.addClass("switch");
            // checkbox.addClass('hidden');
            $(".switch").append('<span class="slider round"></span>');
            // Gérer le changement de l'état du checkbox
            checkbox.change(function () {
                label.toggleClass("on", checkbox.prop("checked"));
                label.toggleClass("off", !checkbox.prop("checked"));
            });
            if (checkboxButton_1) {
                checkboxButton_1.addEventListener("change", function () {
                    var recevoir_bulletin = checkboxButton_1.checked;
                    var formData = new FormData();
                    formData.append("recevoir_bulletin", recevoir_bulletin);
                    formData.append("preferred_language", preferredLanguageUser_1);
                    fetch(url, {
                        method: "POST",
                        body: formData,
                    })
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                        if (data.messages && data.messages.length > 0) {
                            data.messages.forEach(function (message) {
                                showNotification(message, "success");
                            });
                        }
                        else {
                            showNotification("No messages to display", "neutral");
                        }
                    })
                        .catch(function (error) {
                        console.log("Erreur lors de la sauvegarde des données :", error);
                    });
                });
            }
        }
    });
};
