var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import $ from "jquery";
export var MobNav = function () {
    var _a;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    $('#mobile-nav-icon').on('click', function () {
        $('.mob-nav-panel').slideToggle('fast', 'linear');
        var imgSrc = $(this).attr('src');
        var j;
        if (imgSrc.includes('menu-w.svg')) {
            j = imgSrc.replace("menu-w.svg", "cross-w.svg");
        }
        else {
            j = imgSrc.replace("cross-w.svg", "menu-w.svg");
        }
        $(this).attr('src', j);
    });
    $('.dropdown-label').on('click', function () {
        var parentDiv = $(this).parent().closest('div');
        var dataState = parentDiv.parent().closest('[data-state]');
        dataState.attr('data-state') === 'show' ? dataState.attr('data-state', '') : dataState.attr('data-state', 'show');
        $(this).toggleClass('active');
    });
    function setRedDots() {
        return __awaiter(this, void 0, void 0, function () {
            var responseChat, dataChat, mobileMenuChat, responseRoom, dataRoom, mobileMenuRoom;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/notifications/?notification_channel=messagerie&unread=true&per_page=1"))];
                    case 1:
                        responseChat = _a.sent();
                        return [4 /*yield*/, responseChat.json()];
                    case 2:
                        dataChat = _a.sent();
                        mobileMenuChat = document.getElementById("nx-mobile-menu-chats-reddot");
                        if (mobileMenuChat) {
                            if (dataChat.count > 0) {
                                mobileMenuChat.style.display = "block";
                            }
                            else {
                                mobileMenuChat.style.display = "none";
                            }
                        }
                        return [4 /*yield*/, fetch("/".concat(langCode, "/api/notifications/?notification_channel=forum&unread=true&per_page=1"))];
                    case 3:
                        responseRoom = _a.sent();
                        return [4 /*yield*/, responseRoom.json()];
                    case 4:
                        dataRoom = _a.sent();
                        mobileMenuRoom = document.getElementById("nx-mobile-menu-rooms-reddot");
                        if (mobileMenuRoom) {
                            if (dataRoom.count > 0) {
                                // api doesn't work with "unread=true", need to fix it
                                // mobileMenuRoom.style.display = "block";
                                mobileMenuRoom.style.display = "none";
                            }
                            else {
                                mobileMenuRoom.style.display = "none";
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    setRedDots();
};
