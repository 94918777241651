export function showNotification(message, variant) {
    if (variant === void 0) { variant = "neutral"; }
    var parentContainer = document.createElement('div');
    parentContainer.className = 'container-fluid';
    var notificationContainer = document.createElement('div');
    notificationContainer.className = "slide-in-from-right popup-note d-flex flex-row justify-content-between";
    if (variant === "success") {
        notificationContainer.style.backgroundColor = "#4f813d";
    }
    else if (variant === "error") {
        notificationContainer.style.backgroundColor = "#cb381f";
    }
    else {
        notificationContainer.style.backgroundColor = "#095797";
    }
    var messageElement = document.createElement('div');
    messageElement.className = "txt";
    messageElement.textContent = message;
    var closeButton = document.createElement('img');
    closeButton.src = "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross-w.svg");
    closeButton.alt = "close";
    closeButton.className = "close";
    closeButton.style.cursor = "pointer";
    closeButton.addEventListener('click', function () {
        notificationContainer.remove();
    });
    notificationContainer.appendChild(messageElement);
    notificationContainer.appendChild(closeButton);
    parentContainer.appendChild(notificationContainer);
    document.body.appendChild(parentContainer);
    setTimeout(function () {
        notificationContainer.remove();
    }, 4000);
}
