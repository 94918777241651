import $ from "jquery";
export var BonsCoupsForm = function () {
    // Function for attaching the click event handler to the delete button
    function attachDeleteButtonHandler(button) {
        button.addEventListener("click", function () {
            var formset = button.closest("span");
            formset.style.display = "none";
        });
    }
    // Function to create a new form with the delete button
    function createFormWithDeleteButton(form_idx) {
        var emptyForm = $("#empty_form_boncoup")
            .html()
            .replace(/__prefix__/g, form_idx);
        var formset = $(emptyForm);
        var checkbox = formset.find(".checkboxinput");
        var deleteField = formset.find('input[name*="DELETE"]');
        var deleteLabel = formset.find('label[for*="DELETE"]');
        var langCode = document.getElementById("language-code").value;
        var deleteButtonText = langCode === "fr" ? "Supprimer" : "Delete";
        var deleteButton = $("<button type=\"button\" class=\"delete-button\">".concat(deleteButtonText, "</button>"));
        checkbox.hide();
        deleteLabel.hide();
        deleteButton.click(function () {
            checkbox.prop("checked", true);
            deleteField.val("on");
            formset.hide();
        });
        formset.append(deleteButton);
        attachDeleteButtonHandler(deleteButton[0]);
        return formset;
    }
    // Function to hide the checkbox and add the delete button
    function updateFormset(formset) {
        var checkbox = formset.find("input[type='checkbox']");
        var deleteField = formset.find('input[name*="DELETE"]');
        var deleteLabel = formset.find('label[for*="DELETE"]');
        var langCode = document.getElementById("language-code").value;
        var deleteButtonText = langCode === "fr" ? "Supprimer" : "Delete";
        var deleteButton = $("<button type=\"button\" class=\"delete-button\">".concat(deleteButtonText, "</button>"));
        checkbox.hide();
        deleteLabel.hide();
        deleteButton.click(function () {
            checkbox.prop("checked", true);
            deleteField.val("on");
            formset.hide();
        });
        formset.append(deleteButton);
    }
    // Add a delete button to existing formsets when the page is loaded
    $("#boncoup_documents_formset span").each(function () {
        var formset = $(this);
        updateFormset(formset);
        attachDeleteButtonHandler(formset.find(".delete-button")[0]);
    });
    $(document).ready(function () {
        $("#bonscoups_documents_add_more").on("click", function () {
            var form_idx = $("#id_form-TOTAL_FORMS").val();
            var formset = createFormWithDeleteButton(form_idx);
            $("#boncoup_documents_formset").append(formset);
            $("#id_form-TOTAL_FORMS").val(parseInt(form_idx) + 1);
        });
        // UPDATE BUTTON DETELE FOR EXISTING FORMS
        $("#boncoup_documents_formset .nx-add-documents-item-new").each(function () {
            var formset = $(this);
            updateFormset(formset);
            attachDeleteButtonHandler(formset.find(".delete-button")[0]);
        });
    });
};
