import $ from "jquery";
export var InputDropdownMultiSelection = function () {
    var elem = $('.input-wrapper-selection');
    $('.i-input').on('click', function () {
        var thisIs = this;
        var select = $('[data-select=' + $(this).data('label'));
        select.toggleClass('show');
        $('.i-option', select).on('click', function () {
            var arr = [];
            $.each($('input[type="checkbox"]', select), function () {
                if ($(this).is(':checked') && !arr.includes($(this).val())) {
                    arr.push($(this).val());
                }
            });
            $('input[type="text"]', $(thisIs)).val(arr.join(', '));
        });
    });
    $(document).on('click', function (e) {
        if ($(e.target).closest('.i-select', elem).length === 0 && $(e.target).closest('.i-input', elem).length === 0) {
            $('.i-select', elem).removeClass('show');
        }
    });
};
