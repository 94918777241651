import $ from "jquery";
export var AddAdresse = function () {
    $(document).ready(function () {
        var currentLocal = $("#language-code").val();
        // Fonction pour enlever les accents
        function removeAccents(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        var input = $("#id_company_form_addresses-auto_complete_address")[0];
        // Ajouter un écouteur sur le changement de valeur
        if (input) {
            input.addEventListener("change", function () {
                $("#id_company_form_addresses-no_porte").val("");
                $("#id_company_form_addresses-nom_rue").val("");
                $("#id_company_form_addresses-code_postal").val("");
                $("#id_company_form_addresses-ville").prop("selectedIndex", 0);
                $("#id_company_form_addresses-region_adminsitrative").prop("selectedIndex", 0);
                $("#id_company_form_addresses-mrc").prop("selectedIndex", 0);
                $("#id_company_form_addresses-province").prop("selectedIndex", 0);
            });
        }
        /*global google*/
        /*eslint no-undef: "error"*/
        if (input) {
            var autocomplete = new google.maps.places.Autocomplete(input, {
                componentRestrictions: { country: "ca" },
            });
            input.placeholder = currentLocal === "fr" ? "Entrez une adresse" : "Enter an address";
            autocomplete.addListener("place_changed", function () {
                var place = autocomplete.getPlace();
                var components = place.address_components;
                var street_number = null;
                var route = null;
                var city = null;
                var postal_code = null;
                var mrc = null;
                var region_administrative = null;
                var province = null;
                for (var i = 0; i < components.length; i++) {
                    var component = components[i];
                    switch (component.types[0]) {
                        case "street_number":
                            street_number = component.long_name;
                            break;
                        case "route":
                            route = component.long_name;
                            break;
                        case "locality":
                            city = component.long_name;
                            break;
                        case "postal_code":
                            postal_code = component.short_name;
                            break;
                    }
                }
                $("#id_company_form_addresses-no_porte").val(street_number);
                $("#id_company_form_addresses-nom_rue").val(route);
                $("#id_company_form_addresses-code_postal").val(postal_code);
                var select_city = $("#id_company_form_addresses-ville")[0];
                var select_region_administrative = $("#id_company_form_addresses-region_adminsitrative")[0];
                var select_mrc = $("#id_company_form_addresses-mrc")[0];
                var select_province = $("#id_company_form_addresses-province")[0];
                var select_odonyme_generique = $("#id_company_form_addresses-odonyme_generique")[0];
                for (var j = 0; j < select_city.options.length; j++) {
                    if (select_city.options[j].text === city) {
                        $("#id_company_form_addresses-ville").val(select_city.options[j].value).trigger("change");
                        break;
                    }
                }
                for (var k = 0; j < select_odonyme_generique.options.length; k++) {
                    if (select_odonyme_generique.options[k].text === city) {
                        $("#id_company_form_addresses-odonyme_generique").val(select_odonyme_generique.options[j].value).trigger("change");
                        break;
                    }
                }
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: place.formatted_address }, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            var lat = results[0].geometry.location.lat();
                            var lng = results[0].geometry.location.lng();
                            var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=".concat(lat, ",").concat(lng, "&key=").concat(process.env.GOOGLE_KEY);
                            $.get(url, function (data) {
                                for (var i = 0; i < data.results.length; i++) {
                                    var result = data.results[i];
                                    for (var j = 0; j < result.address_components.length; j++) {
                                        var addressComponent = result.address_components[j];
                                        if (addressComponent.types.indexOf("administrative_area_level_2") !== -1) {
                                            region_administrative = addressComponent.long_name;
                                            break;
                                        }
                                        if (addressComponent.types.indexOf("administrative_area_level_3") !== -1) {
                                            mrc = addressComponent.long_name;
                                            break;
                                        }
                                        if (addressComponent.types.indexOf("administrative_area_level_1") !== -1) {
                                            province = addressComponent.long_name;
                                            break;
                                        }
                                    }
                                    if (region_administrative !== null && mrc !== null && province !== null) {
                                        break;
                                    }
                                }
                                // Sélectionner les options correspondantes dans les menus déroulants
                                for (var k = 0; k < select_region_administrative.options.length; k++) {
                                    var optionText = select_region_administrative.options[k].text;
                                    var regionAdministrativeWithoutAccents = removeAccents(region_administrative.toLowerCase());
                                    if (removeAccents(optionText.toLowerCase()) === regionAdministrativeWithoutAccents) {
                                        $("#id_company_form_addresses-region_adminsitrative").val(select_region_administrative.options[k].value).trigger("change");
                                        break;
                                    }
                                }
                                for (var n = 0; n < select_mrc.options.length; n++) {
                                    if (select_mrc.options[n].text === mrc || mrc.includes(select_mrc.options[n].text)) {
                                        $("#id_company_form_addresses-mrc").val(select_mrc.options[n].value).trigger("change");
                                        break;
                                    }
                                    if (n == select_mrc.options.length - 1) {
                                        var hors_mrc = Object.values(select_mrc.options).find(function (item) { return item.text.toLowerCase() == "hors-mrc"; });
                                        $("#id_company_form_addresses-mrc").val(hors_mrc.value).trigger("change");
                                    }
                                }
                                for (var c = 0; c < select_province.options.length; c++) {
                                    if (removeAccents(select_province.options[c].text.toLowerCase()) === removeAccents(province.toLowerCase())) {
                                        $("#id_company_form_addresses-province").val(select_province.options[c].value).trigger("change");
                                        break;
                                    }
                                }
                            });
                        }
                    }
                });
            });
        }
    });
};
