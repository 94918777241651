import $ from "jquery";
export var Registration = function () {
    $(document).ready(function () {
        $("input").removeAttr("required");
        $("#div_id_1-confirm_neq").css("display", "none");
        var language = document.getElementById("language-code").value;
        var typeCompanyField = $("input[name='1-type_company']");
        hideNeqFieldIfNeeded();
        typeCompanyField.on("change", function () {
            hideNeqFieldIfNeeded();
        });
        // Gérer l'affichage ou le masquage du champ "neq" après la validation du formulaire
        $("#form_registration_step_1").on("submit", function () {
            hideNeqFieldIfNeeded();
        });
        function hideNeqFieldIfNeeded() {
            var selectedType = typeCompanyField.filter(":checked").val();
            if (selectedType === "intervenant") {
                $("#div_id_1-neq").hide();
            }
            else {
                $("#div_id_1-neq").show();
            }
        }
        var neq = $("#id_1-neq").val();
        // var neq_field = $("#id_1-neq");
        var url;
        if (neq) {
            if (language === "fr") {
                url = "/fr/entreprises/check-neq-existence/" + neq + "/";
            }
            else {
                url = "/en/companies/check-neq-existence/" + neq + "/";
            }
            $.ajax({
                url: url,
                type: "GET",
                data: { neq: neq },
                success: function (response) {
                    if (response.exists) {
                        // Afficher la case à cocher "confirm_neq" si le NEQ existe
                        $("#div_id_1-confirm_neq").show();
                    }
                },
            });
        }
        /* !! commented for task: Plan Nord - Allow NEQ with any digits and dashes
        var invalidChars = ["-", "e", "+", "E"];
        neq_field.on("keydown", function (e) {
          if (invalidChars.includes(e.key)) {
            e.preventDefault();
          }
        });
        */
    });
};
