import $ from "jquery";
export var Workers = function () {
    var language = document.getElementById("language-code").value;
    $('select[name="role"]').on("change", function () {
        var newRoleId = $(this).val();
        var userId = $(this).attr("data-user-id");
        var url;
        if (language === "fr") {
            url = "/fr/entreprises/update/user/" + userId + "/";
        }
        else {
            url = "/en/companies/update/user/" + userId + "/";
        }
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": "{{ csrf_token }}",
            },
            body: JSON.stringify({ role_id: newRoleId }),
        })
            .then(function (response) {
            if (response.ok) {
                window.location.reload();
            }
        })
            .catch(function (error) { return console.error(error); });
    });
    $('select[name="group_permission"]').on("change", function () {
        var newGroupId = $(this).val();
        var userId = $(this).attr("data-group-user-id");
        var url;
        if (language === "fr") {
            url = "/fr/entreprises/update/user/" + userId + "/";
        }
        else {
            url = "/en/companies/update/user/" + userId + "/";
        }
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": "{{ csrf_token }}",
            },
            body: JSON.stringify({ group_id: newGroupId }),
        })
            .then(function (response) {
            if (response.ok) {
                window.location.reload();
            }
        })
            .catch(function (error) { return console.error(error); });
    });
};
