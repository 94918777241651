export var MenuProfileCompany = function () {
    // Vérifier si la largeur de l'écran est inférieure à 767px
    if (window.innerWidth <= 767) {
        var toggleIcons_1 = document.querySelectorAll('.toggle-icon');
        var firstLi_1 = document.getElementById('first-li');
        firstLi_1.style.display = 'block';
        // Fonction pour rechercher et afficher le contenu de l'élément avec la classe "active"
        var displayActiveContent = function () {
            var firstSpan = firstLi_1.querySelector('span');
            var breadcrumbs = document.querySelector('.breadcrumbs.padding-sm-8px');
            var breadcrumbsListItems = breadcrumbs.querySelectorAll('li');
            breadcrumbsListItems.forEach(function (breadcrumb) {
                if (breadcrumb.classList.contains('curitem')) {
                    firstSpan.textContent = breadcrumb.textContent.trim();
                }
            });
        };
        // Appeler la fonction au chargement de la page
        displayActiveContent();
        // Afficher l'icône "up" et cacher l'icône "down" initialement
        toggleIcons_1.forEach(function (icon) {
            if (icon.classList.contains('down')) {
                icon.style.display = 'inline-block';
            }
            else {
                icon.style.display = 'none';
            }
        });
        toggleIcons_1.forEach(function (icon) {
            var adjacentLis = icon.parentNode.parentNode.parentNode.parentElement.querySelectorAll('li:not(:first-child)');
            adjacentLis.forEach(function (li) {
                li.style.display = 'none';
            });
        });
        toggleIcons_1.forEach(function (icon) {
            icon.addEventListener('click', function (event) {
                event.preventDefault();
                var parentListItem = this.closest('li');
                var siblingListItems = parentListItem.parentNode.querySelectorAll('li:not(:first-child)');
                siblingListItems.forEach(function (item) {
                    if (item !== parentListItem) {
                        item.style.display = item.style.display === 'none' ? 'block' : 'none';
                    }
                    var breadcrumbsSelector = '.breadcrumbs.padding-sm-8px';
                    var breadcrumb = document.querySelector(breadcrumbsSelector).querySelector('.curitem');
                    var breadcrumbHref = breadcrumb.querySelector('a').getAttribute('href');
                    var itemHref = item.querySelector('a').getAttribute('href');
                    if (itemHref.toString() === breadcrumbHref.toString()) {
                        if (item.classList.contains('speciale-one')) {
                            item.classList.remove('active');
                        }
                        else {
                            item.classList.add('active');
                        }
                    }
                    else {
                        item.classList.remove('active');
                    }
                });
                var downIcon = parentListItem.querySelector('.toggle-icon.down');
                var upIcon = parentListItem.querySelector('.toggle-icon.up');
                downIcon.style.display = downIcon.style.display === 'none' ? 'inline-block' : 'none';
                upIcon.style.display = upIcon.style.display === 'none' ? 'inline-block' : 'none';
            });
        });
        // Fonction pour afficher ou masquer le menu lorsque le premier élément de la liste est cliqué
        var toggleMenu_1 = function () {
            var siblingListItems = firstLi_1.parentNode.querySelectorAll('li:not(:first-child)');
            siblingListItems.forEach(function (item) {
                item.style.display = item.style.display === 'none' ? 'block' : 'none';
            });
            // Inverser l'affichage des icônes
            toggleIcons_1.forEach(function (icon) {
                icon.style.display = icon.style.display === 'none' ? 'inline-block' : 'none';
            });
        };
        // Ajouter un gestionnaire d'événements de clic au premier élément de la liste pour ouvrir/fermer le menu
        firstLi_1.addEventListener('click', toggleMenu_1);
        // Ajouter un gestionnaire d'événements de clic aux icônes pour ouvrir/fermer le menu
        toggleIcons_1.forEach(function (icon) {
            icon.addEventListener('click', toggleMenu_1);
        });
    }
};
