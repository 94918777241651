export var SuccursalesCompanies = function () {
    /*global google*/
    /*eslint no-undef: "error"*/
    var $ = window.jQuery;
    function initMapSuccursale() {
        var mapDiv = document.getElementById("map_succusale_company");
        if (mapDiv) {
            var map = new google.maps.Map(mapDiv, {
                zoom: 5,
                center: { lat: 55.5877294, lng: -65.191206 },
            });
            var addresses = document.getElementsByClassName("address");
            if (addresses.length) {
                var bounds = new google.maps.LatLngBounds();
                var all_infopops_1 = [];
                var _loop_1 = function () {
                    if (addresses[i].getAttribute("data-addrlat")) {
                        address = addresses[i].getAttribute("data-address");
                        var addr_lat = parseFloat(addresses[i].getAttribute("data-addrlat").replace(",", "."));
                        var addr_lon = parseFloat(addresses[i].getAttribute("data-addrlon").replace(",", "."));
                        var location_1 = {
                            lat: addr_lat,
                            lng: addr_lon,
                        };
                        if (addr_lat && addr_lon) {
                            var infopop_1 = new google.maps.InfoWindow({
                                content: "<div class=\"nx-map-popinfo\"><div class=\"address-box\">                ".concat($(addresses[i]).find(".address-box").html(), "                </div></div>"),
                                pixelOffset: new google.maps.Size(220, 100),
                            });
                            all_infopops_1.push(infopop_1);
                            var marker_1 = new google.maps.Marker({
                                position: location_1,
                                map: map,
                                title: address,
                                icon: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-pin-square.jpg"),
                            });
                            marker_1.addListener("click", function () {
                                var _a;
                                if ((_a = infopop_1.anchor) === null || _a === void 0 ? void 0 : _a.visible) {
                                    infopop_1.close(map, marker_1);
                                }
                                else {
                                    all_infopops_1.forEach(function (infopop) { return infopop.close(); });
                                    infopop_1.open(map, marker_1);
                                }
                            });
                            bounds.extend(location_1);
                        }
                    }
                };
                var address;
                for (var i = 0; i < addresses.length; i++) {
                    _loop_1();
                }
                // Si vous avez une seule adresse, centrez la carte sur cette adresse
                if (addresses.length === 1) {
                    map.setCenter(bounds.getCenter());
                    map.setZoom(10);
                }
                else {
                    map.fitBounds(bounds);
                }
            }
        }
    }
    setTimeout(initMapSuccursale, 2000);
};
