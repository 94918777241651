var cookiesLanguageSettings = {
    fr: {
        consent_modal: {
            title: "Ce site web stocke des cookies sur votre ordinateur.",
            description: 'Ces cookies sont utilisés pour recueillir des informations sur votre interaction avec notre site web et faciliter la personnalisation de votre expérience. <button type="button" data-cc="c-settings" class="cc-link">Laissez-moi choisir</button>',
            primary_btn: {
                text: "Accepter tout",
                role: "accept_all",
            },
            secondary_btn: {
                text: "Rejeter tout",
                role: "accept_necessary",
            },
        },
        settings_modal: {
            title: "Paramètres des cookies",
            save_settings_btn: "Enregistrer les préférences",
            accept_all_btn: "Accepter tous les cookies",
            reject_all_btn: "Rejeter tout",
            close_btn_label: "Fermer",
            cookie_table_headers: [
                { col1: "Nom" },
                { col2: "Domaine" },
                { col3: "Expiration" },
                { col4: "Description" },
            ],
            blocks: [
                {
                    title: "Ce site web stocke des cookies sur votre ordinateur.",
                    description: 'Ces cookies nous aident à comprendre comment vous utilisez notre site et nous permettent de personnaliser votre expérience. Nous utilisons ces informations pour améliorer notre site et analyser le comportement des visiteurs.',
                },
                {
                    title: "Cookies essentiels",
                    description: "Les cookies essentiels ne peuvent pas être configurés car ils nous permettent d'assurer le bon fonctionnement des fonctions de base de notre site web. Cela inclut les cookies de connexion, les cookies d'identifiant de session et les cookies de sécurité.",
                    toggle: {
                        value: "necessary",
                        enabled: true,
                        readonly: true,
                    },
                },
                {
                    title: "Cookies analytiques",
                    description: "Pour recueillir des données statistiques anonymes telles que le nombre de visiteurs, la durée des visites, la fréquence de retour, et la manière dont vous accédez à notre site web.",
                    toggle: {
                        value: "analytics",
                        enabled: false,
                        readonly: false,
                    },
                },
            ],
        },
    },
    en: {
        consent_modal: {
            title: "This website stores cookies on your computer.",
            description: 'These cookies are used to collect information about your interaction with our website and facilitate the customization of your experience. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
            primary_btn: {
                text: "Accept All",
                role: "accept_all",
            },
            secondary_btn: {
                text: "Reject All",
                role: "accept_necessary",
            },
        },
        settings_modal: {
            title: "Cookie Settings",
            save_settings_btn: "Save Preferences",
            accept_all_btn: "Accept All Cookies",
            reject_all_btn: "Reject All",
            close_btn_label: "Close",
            cookie_table_headers: [
                { col1: "Name" },
                { col2: "Domain" },
                { col3: "Expiration" },
                { col4: "Description" },
            ],
            blocks: [
                {
                    title: "This website stores cookies on your computer.",
                    description: 'These cookies help us understand how you use our site and allow us to personalize your experience. We use this information to improve our site and analyze visitor behavior.',
                },
                {
                    title: "Essential Cookies",
                    description: "Essential cookies cannot be configured as they allow us to ensure the proper functioning of the basic features of our website. This includes login cookies, session identifier cookies, and security cookies.",
                    toggle: {
                        value: "necessary",
                        enabled: true,
                        readonly: true,
                    },
                },
                {
                    title: "Analytical Cookies",
                    description: "To collect anonymous statistical data such as the number of visitors, the duration of visits, the frequency of return, and how you access our website.",
                    toggle: {
                        value: "analytics",
                        enabled: false,
                        readonly: false,
                    },
                },
            ],
        },
    },
};
export default cookiesLanguageSettings;
