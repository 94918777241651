import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";
export var Select2Init = function () {
    var _a;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    function formatSelectedOption(option) {
        if (!option.id) {
            return $("<div class=\"text-dark-blue\" style=\"font-weight: bold;\">".concat(option.text, "</div>"));
        }
        return $("<label disables=\"".concat(option.id == "", "\" style=\"display:flex; padding:8px 6px\" for=\"").concat(option.id, "\"><input style=\"margin-top:4px; margin-right:4px\" id=\"").concat(option.id, "\" type=\"checkbox\" ").concat(option.selected ? "checked" : "", " class=\"selected-checkbox\" /> <span style=\"flex: 1 1 0%;\">").concat(option.text, "</span> </label>"));
    }
    $(".select")
        .not("#id_formsetsocialmedia-__prefix__-nom")
        .select2({
        minimumResultsForSearch: 12,
        language: {
            noResults: function () {
                return langCode == "en"
                    ? "No result found"
                    : "Aucun résultat trouvé";
            },
        },
    });
    $(".selectmultiple").select2({
        minimumResultsForSearch: 2,
        templateResult: formatSelectedOption,
        closeOnSelect: false,
        placeholder: langCode == "fr" ? "Sélectionner" : "Select",
        language: {
            noResults: function () {
                return langCode == "en" ? "No result found" : "Aucun résultat trouvé";
            },
        },
    });
    $(".select").on("select2:open", function () {
        $(".select2-dropdown").addClass("custom-select2-class");
    });
    var servicesPlaceholder = langCode == "fr"
        ? "Sélectionner vos produits et/ou services"
        : "Select your products and/or services";
    $("#id_services_produits").select2({
        placeholder: servicesPlaceholder,
        templateResult: formatSelectedOption,
        closeOnSelect: false,
    });
};
