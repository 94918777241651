import $ from "jquery";
var geocodeAndUpdateField = function () {
    var latitude = parseFloat($('#id_latitude_coordonnate').val());
    var longitude = parseFloat($('#id_longitude_coordonnate').val());
    if (!isNaN(latitude) && !isNaN(longitude)) {
        var latlng = new google.maps.LatLng(latitude, longitude);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': latlng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
                var adresseGeocodeeField = $('#id_adresse_geocodee');
                var geocodeData = {
                    address_components: results[0].address_components,
                    formattedAddress: results[0].formatted_address,
                    latitude: latitude,
                    longitude: longitude,
                };
                var geocodeDataJSON = JSON.stringify(geocodeData);
                adresseGeocodeeField.val(geocodeDataJSON);
            }
        });
    }
};
export var ManageProjectOtherAddress = function () {
    /*global google*/
    /*eslint no-undef: "error"*/
    $(document).ready(function () {
        var coordonnate_address_form = document.getElementById("coordonnate_address_form_project");
        if (coordonnate_address_form) {
            geocodeAndUpdateField();
            $('#id_latitude_coordonnate, #id_longitude_coordonnate').on('input', function () {
                geocodeAndUpdateField();
            });
        }
    });
};
