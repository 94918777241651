export var SupplierProfile = function () {
    $(document).ready(function () {
        var _a;
        // Code js for dates:
        var dateIds = ['id_date_constitution', 'id_date_ouverture', 'id_date_recensement'];
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        var language = langCode;
        // var dateFormat = (language === 'fr') ? 'dd/mm/yyyy' : 'yyyy-mm-dd';
        /*global $*/
        /*eslint no-undef: "error"*/
        for (var i = 0; i < dateIds.length; i++) {
            $('#' + dateIds[i]).datepicker({
                orientation: 'bottom auto',
                language: language,
                weekStart: 0,
                autoclose: true,
                changeMonth: true,
                changeYear: true,
                format: (language === 'fr') ? 'dd/mm/yyyy' : 'yyyy-mm-dd'
            });
        }
        // add sticky class to the button when scrolling  
        var saveBtn = document.querySelector('.form-actions');
        var container = document.querySelector('.form-container');
        function handleScroll() {
            if (window.pageYOffset >= container.offsetTop + container.offsetHeight - window.innerHeight) {
                saveBtn.classList.remove('sticky');
            }
            else {
                saveBtn.classList.add('sticky');
            }
        }
        if (container && saveBtn) {
            window.addEventListener('scroll', handleScroll);
        }
    });
};
