import $ from "jquery";
export var InputSideFilter = function () {
    $('[data-toggleid]').on('click', function () {
        $(this).toggleClass('open');
        var img = $('.icon', $(this));
        if ($(this).hasClass('open')) {
            img.attr('src', img.attr('src').replace("down", "up"));
        }
        else {
            img.attr('src', img.attr('src').replace("up", "down"));
        }
        $('#' + $(this).data("toggleid")).fadeToggle(function () {
            $(this).toggleClass('open');
            if ($(this).is(':visible'))
                $(this).css('display', 'flex');
        });
    });
};
