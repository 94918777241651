import $ from "jquery";
export var RegistrationStep2 = function () {
    $(document).ready(function () {
        var form = $('#form-id');
        var language = $("#language-code").val();
        if (form) {
            $('#form-id').on("submit", function () {
                if ($('#form-id input[name="2-terms_and_conditions_url"]').val() === 'yes') {
                    $('#form-id .submit-button').prop('disabled', false);
                }
            });
            var termsCheckbox_1 = form.find('#div_id_2-terms_and_conditions_url');
            $('#form-id input[name="2-terms_and_conditions_url"]').change(function () {
                if ($(this).val() === 'no' && $(this).is(':checked')) {
                    $('#form-id .submit-button').prop('disabled', true);
                }
                else {
                    $('#form-id .submit-button').prop('disabled', false);
                }
            });
            if (termsCheckbox_1.length > 0) {
                var labelElement_1 = termsCheckbox_1.find('label.control-label');
                var labelText = labelElement_1.text().trim();
                var url;
                if (language === "fr") {
                    url = "/fr/utilisateurs/get-terms-and-conditions/";
                }
                else {
                    url = "/en/users/get-terms-and-conditions/";
                }
                if (labelText.length > 0) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            var linkElement = $('<a>', {
                                href: data.terms_and_conditions_link,
                                text: language === "fr" ? 'les termes et conditions' : 'the terms and conditions',
                                target: '_blank'
                            });
                            var excludedTextElement = $('<span>', {
                                text: language === "fr" ? 'Je reconnais avoir lu, compris et accepté ' : 'I acknowledge having read, understood and accepted '
                            });
                            var networkText = language === "fr" ? ' du Réseau d’affaires nordiques.' : ' of the Réseau d\'affaires nordiques.';
                            labelElement_1.empty().append(excludedTextElement).append(linkElement).append(networkText);
                            labelElement_1.append('<span class="asteriskField">*</span>');
                            var divLabel = termsCheckbox_1.find('.controls label');
                            divLabel.css('display', 'inline-block');
                            var radioInputs = termsCheckbox_1.find('.controls input[type="radio"]');
                            radioInputs.css('margin-right', '10px');
                        },
                        error: function (error) {
                            console.error('Error fetching terms and conditions:', error);
                        }
                    });
                }
            }
        }
    });
};
