export var ContractorProfile = function () {
    $(document).ready(function () {
        /*global $*/
        /*eslint no-undef: "error"*/
        // Récupération de la langue actuelle
        var language = document.getElementById("language-code").value;
        var legend;
        if (language === 'fr') {
            legend = "Politiques internes";
        }
        else if (language === 'en') {
            legend = "Internal policies";
        }
        // find the fieldset with the legend "Politiques internes"
        var fieldset = $("fieldset:has(legend:contains('" + legend + "'))");
        // find all radio buttons within the fieldset
        var radios = fieldset.find("input[type='radio']");
        // handle the change event for the radio buttons
        radios.change(function () {
            // Construire les attributs data-url-input-id pour les deux langues
            var urlInputIdFr = $(this).data('url-input-id-fr');
            var urlInputIdEn = $(this).data('url-input-id-en');
            // Trouver les champs URL correspondants dans le fieldset
            var urlInputFr = fieldset.find("input#" + urlInputIdFr);
            var urlInputEn = fieldset.find("input#" + urlInputIdEn);
            if (urlInputFr.length === 0 || urlInputEn.length === 0) {
                console.error("Aucun champ URL trouvé avec les ID " + urlInputIdFr + " ou " + urlInputIdEn);
                return;
            }
            // Ajouter ou supprimer l'astérisque à côté des labels des deux champs URL
            if ($(this).val() === "True") {
                urlInputFr.closest('.control-group').find('label').html(function (i, html) {
                    return html + '<span class="asterisk"> *</span>';
                });
                urlInputEn.closest('.control-group').find('label').html(function (i, html) {
                    return html + '<span class="asterisk"> *</span>';
                });
            }
            else {
                urlInputFr.closest('.control-group').find('label span.asterisk').remove();
                urlInputEn.closest('.control-group').find('label span.asterisk').remove();
                urlInputFr.val('');
                urlInputEn.val('');
            }
        });
        // Initialisation du plugin Bootstrap Datepicker
        $('#id_date_prevision_construction').datepicker({
            orientation: 'bottom auto',
            language: language,
            weekStart: 0,
            autoclose: true,
            changeMonth: true,
            changeYear: true,
            format: (language === 'fr') ? 'dd/mm/yyyy' : 'yyyy-mm-dd'
        });
        $('#id_date_prevision_exploration_commerciale').datepicker({
            language: language,
            todayBtn: "linked",
            clearBtn: true,
            autoclose: true,
            format: (language === 'fr') ? 'dd/mm/yyyy' : 'yyyy-mm-dd'
        });
    });
};
