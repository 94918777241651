import $ from "jquery";
export var CompanyFormsetNord49 = function () {
    var processFormset = function (formset) {
        var checkbox = formset.find('.checkboxinput');
        checkbox.hide();
        var deleteField = formset.find('input[name*="DELETE"]');
        var deleteLabel = formset.find('label[for*="DELETE"]');
        deleteLabel.hide();
        var langCode = document.getElementById("language-code").value;
        var deleteButtonText = langCode === "fr" ? "Supprimer" : "Delete";
        var deleteButton = $("<button type=\"button\" class=\"btn btn-danger mb-2\">".concat(deleteButtonText, "</button>"));
        deleteButton.click(function () {
            checkbox.prop("checked", true);
            deleteField.val("on");
            formset.hide();
        });
        formset.append(deleteButton);
    };
    var initExistingFormsets = function () {
        $('#formsetworker_formset span[id$="_worker"]').each(function () {
            var formset = $(this);
            processFormset(formset);
        });
    };
    $(document).ready(function () {
        initExistingFormsets();
        $("#add_more_worker").click(function () {
            var form_idx = $("#id_formsetworker-TOTAL_FORMS").val();
            var newEmptyForm = $("#empty_form_worker")
                .html()
                .replace(/__prefix__/g, form_idx);
            var newFormset = $(newEmptyForm);
            processFormset(newFormset);
            $("#formsetworker_formset").append(newFormset);
            $("#id_formsetworker-TOTAL_FORMS").val(parseInt(form_idx) + 1);
        });
    });
};
