import $ from "jquery";
/**
 Register Custom event on the current DOM so when we click dropdown it will register it and fire events inside
 React Components
*/
export var NotificationDropDown = function () {
    /**
          We create custom event that detect the Open of notification DropDown
          Why this is useful ?
          because we need to Load notification when user open the dropdown
      **/
    var forumNotifications = document.getElementById("forumNotificationsToggle");
    if (forumNotifications) {
        forumNotifications.addEventListener("show.bs.dropdown", function () {
            var event = new CustomEvent("forumNotificationsDropDownOpened");
            document.getElementById("notification-widget").dispatchEvent(event);
        });
    }
    /**
          We create custom event that detect the Close of notification DropDown
          Why this is useful ?
          because we need to set notification read status to READ when we close the dropdown
      **/
    if (forumNotifications) {
        forumNotifications.addEventListener("hide.bs.dropdown", function () {
            var event = new CustomEvent("forumNotificationsDropDownClosed");
            document.getElementById("notification-widget").dispatchEvent(event);
        });
    }
};
export var TwillioNotificationDropDown = function () {
    /**
          We create custom event that detect the Open of notification DropDown
          Why this is useful ?
          because we need to Load notification when user open the dropdown
      **/
    var twillioNotifications = document.getElementById("twillioNotificationsToggle");
    if (twillioNotifications) {
        twillioNotifications.addEventListener("show.bs.dropdown", function () {
            $("#messagerie-new-message").hide();
            var event = new CustomEvent("twillioNotificationsDropDownOpened");
            document
                .getElementById("twillio-notification-widget")
                .dispatchEvent(event);
        });
    }
    /**
          We create custom event that detect the Close of notification DropDown
          Why this is useful ?
          because we need to set notification read status to READ when we close the dropdown
      **/
    if (twillioNotifications) {
        twillioNotifications.addEventListener("hide.bs.dropdown", function () {
            var event = new CustomEvent("twillioNotificationsDropDownClosed");
            document
                .getElementById("twillio-notification-widget")
                .dispatchEvent(event);
        });
    }
};
