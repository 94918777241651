import $ from "jquery";
export var RichTextPlugins = function () {
    var quotes = $(".nx-quote");
    if (quotes.length) {
        quotes.each(function () {
            if ($(this).text().length > 140) {
                $(this).addClass("nx-quote-long");
            }
            else {
                $(this).addClass("nx-quote-short");
            }
        });
    }
};
