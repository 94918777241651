import $ from "jquery";
export var PopupNotes = function () {
    /** Demo controllers */
    $.each($('.display-popup-note'), function () {
        var j = $(this);
        $('.display-link', j).on('click', function () {
            $('.popup-note', j).css('display', 'flex');
            setTimeout(function () {
                $('.popup-note', j).fadeOut();
            }, 3000);
            $('.close', j).on('click', function () {
                $('.popup-note', j).fadeOut();
            });
        });
    });
    /** Component controllers */
    $.each($('.popup-note'), function () {
        var elem = $(this);
        setTimeout(function () {
            elem.fadeOut();
        }, 3000);
    });
};
