export var IntervenerSupplierProfile = function () {
    var toggleFormset = document.querySelectorAll("input[name='actif_nord_49_parallele']");
    var formset = document.getElementsByClassName("formset_nord_49_parallele")[0];
    var toggleFormsetExperiencePertinente = document.querySelectorAll("input[name='experiences_pertinentes']");
    var formsetexperiencequebecetranger = document.getElementsByClassName("formsetexperiencequebecetranger")[0];
    $(document).ready(function () {
        /*global $*/
        /*eslint no-undef: "error"*/
        var checkedRadio = document.querySelector("input[name='actif_nord_49_parallele']:checked");
        var checkedRadioExperiencePertinente = document.querySelector("input[name='experiences_pertinentes']:checked");
        if (formset) {
            if (checkedRadio && checkedRadio.value === "True") {
                formset.style.display = "block";
            }
            else {
                formset.style.display = "none";
            }
            toggleFormset.forEach(function (element) {
                element.addEventListener("change", function () {
                    if (this.value === "True") {
                        formset.style.display = "block";
                    }
                    else {
                        formset.style.display = "none";
                    }
                });
            });
        }
        if (formsetexperiencequebecetranger) {
            if (checkedRadioExperiencePertinente && checkedRadioExperiencePertinente.value === "True") {
                formsetexperiencequebecetranger.style.display = "block";
            }
            else {
                formsetexperiencequebecetranger.style.display = "none";
            }
            toggleFormsetExperiencePertinente.forEach(function (element) {
                element.addEventListener("change", function () {
                    if (this.value === "True") {
                        formsetexperiencequebecetranger.style.display = "block";
                    }
                    else {
                        formsetexperiencequebecetranger.style.display = "none";
                    }
                });
            });
        }
    });
};
