import $ from "jquery";
export var RangeSliders = function () {
    $('.range-sliders').each(function () {
        var tThis = this;
        $('.set-tooltip', tThis).html($('.slider-bar', this).val());
        var seatedYear = 0;
        $('.slider-bar', tThis).on('input', function () {
            seatedYear = parseInt($(this).val());
            var setTooltipElem = $('.set-tooltip', tThis);
            var minVal = parseInt($(this).attr('min'));
            var maxVal = parseInt($(this).attr('max'));
            var leftPosition = (seatedYear - minVal) / (maxVal - minVal) * 100;
            setTooltipElem.html(seatedYear);
            setTooltipElem.css("left", leftPosition + "%");
        });
        $('.slider-bar', tThis).on('change', function () {
            if (!window.location.search.trim()) {
                window.location = '?y=' + seatedYear;
            }
            else if (window.location.search.includes('y=')) {
                var search = window.location.search;
                var arr_1 = search.slice(1).split('&');
                $.each(arr_1, function (key, val) {
                    if (val.includes('y=')) {
                        arr_1[key] = 'y=' + seatedYear;
                    }
                });
                window.location = '?' + arr_1.join('&');
            }
            else {
                window.location = window.location.search + "&y=" + seatedYear;
            }
        });
        positionElem(tThis, seatedYear);
    });
    function positionElem(tThis, seatedYear) {
        var elem = $('.slider-bar', tThis);
        seatedYear = parseInt(elem.val());
        var setTooltipElem = $('.set-tooltip', tThis);
        var minVal = parseInt(elem.attr('min'));
        var maxVal = parseInt(elem.attr('max'));
        var leftPosition = (seatedYear - minVal) / (maxVal - minVal) * 100;
        setTooltipElem.html(seatedYear);
        setTooltipElem.css("left", leftPosition + "%");
    }
};
