import $ from "jquery";
export var userProfileTabs = function () {
    var btnEditUserInfo = $('#btn-edit-user-info');
    var userInfoDisplay = $('#user-info-display');
    var userInfoEdit = $('#user-info-edit');
    btnEditUserInfo.on('click', function () {
        $(this).hide();
        userInfoDisplay.hide();
        userInfoEdit.show();
    });
    $('#pills-2-tab, #pills-3-tab').on('click', function () {
        btnEditUserInfo.hide();
    });
    $('#pills-1-tab').on('click', function () {
        btnEditUserInfo.show();
        userInfoDisplay.show();
        userInfoEdit.hide();
    });
};
