import $ from "jquery";
export var SubMenu = function () {
    function toggleArrow(imgElem, before, after) {
        imgElem.attr('src', imgElem.attr('src').replace(before, after));
    }
    $("[data-target*='sub-menu-']").hover(function () {
        var id = $(this).data('target');
        var subMenu = $('#' + id);
        var iconImg = $('img', $(this));
        subMenu.fadeIn("fast", function () {
            toggleArrow(iconImg, 'down', 'up');
        });
    });
    $("[data-target*='sub-menu-']").mouseleave(function () {
        var id = $(this).data('target');
        var subMenu = $('#' + id);
        var iconImg = $('img', $(this));
        subMenu.fadeOut("fast", function () {
            toggleArrow(iconImg, 'up', 'down');
        });
    });
};
