/* eslint-disable */
import cookiesLanguageSettings from "./cookiesLanguageSettings";
/**
 * @class CookieConsent
 */
var CookieConsent = /** @class */ (function () {
    /**
     * Creates an instance of CookieConsent.
     *
     * @memberof CookieConsent
     */
    function CookieConsent() {
        /**
         * Init cookie consent modal
         */
        this.cookiesConsentModal = initCookieConsent();
    }
    /**
     * init the class
     */
    CookieConsent.prototype.ready = function () {
        var _a;
        this.cookiesConsentModal.run({
            // We need set language value in base.html
            current_lang: ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "fr",
            autoclear_cookies: true,
            cookie_name: "nixa_cookies_consent",
            cookie_expiration: 365,
            page_scripts: true,
            autorun: true,
            force_consent: true,
            gui_options: {
                consent_modal: {
                    layout: "box",
                    position: "bottom right",
                    transition: "slide", // zoom,slide
                },
                settings_modal: {
                    layout: "box",
                    // position: 'left', // right,left (available only if bar layout selected)
                    transition: "slide", // zoom,slide
                },
            },
            languages: {
                en: cookiesLanguageSettings.en,
                fr: cookiesLanguageSettings.fr,
            },
        });
    };
    return CookieConsent;
}());
export default new CookieConsent();
