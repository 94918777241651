import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import fr from "flatpickr/dist/l10n/fr.js";
import { French } from "flatpickr/dist/l10n/fr.js";
French.weekdays.shorthand = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
French.firstDayOfWeek = 0;
flatpickr.l10ns.default.weekdays.shorthand = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export var DateTimeProjects = function () {
    $(document).ready(function () {
        /*global $*/
        /*eslint no-undef: "error"*/
        var icone1 = $('<div class="flatpickr-icone"></div>');
        var icone2 = $('<div class="flatpickr-icone"></div>');
        $('#id_date_publication_avis').before(icone1);
        $('#id_date_fermeture_avis').before(icone2);
        var language = document.getElementById("language-code").value;
        if (language === "fr") {
            flatpickr.localize(fr);
        }
        flatpickr("#id_date_publication_avis", {
            enableTime: true,
            dateFormat: language === "fr" ? "d/m/Y H:i:S" : "Y-m-d H:i:S",
            locale: language,
            disableMobile: "true",
        });
        flatpickr("#id_date_fermeture_avis", {
            enableTime: true,
            dateFormat: language === "fr" ? "d/m/Y H:i:S" : "Y-m-d H:i:S",
            locale: language,
            disableMobile: "true",
        });
    });
};
